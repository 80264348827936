.top_select[data-v-44bbb605] {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
}
.foot[data-v-44bbb605] {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.el-form-item[data-v-44bbb605] {
  margin-bottom: 22px !important;
}
.record[data-v-44bbb605] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.record[data-v-44bbb605]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}